import styled from "styled-components";
import { COLORS } from 'Values/Theme'

export const SectionContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: row;
min-height: 500px ;
width: 100%;
background: linear-gradient(
          180deg,
          #262b3e 0%,
          #262b3e 100%
        ),
        linear-gradient(180deg,#262b3e 0% , #262b3e 100% );
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px ;
  flex-direction: column;
  gap : 20px;
  justify-content: center ;
  width: 60% ;

  @media screen and (max-width: 768px){
    width: 90% ;
    flex-direction: column;
  }


  hr{
    margin: 10px 0px ;
    border-color: ${COLORS.grey} ;
  }
`;

export const Section = styled.div`
  display: flex ;
  gap : 10px;

  color : white;
  flex-direction: column ;
  width: 25% ;

  p{
    color : ${COLORS.border_color};
    line-height : 1.6; 
    font-size: medium ;
    margin-bottom: 5px ;
  }

  @media screen and (max-width: 768px){
    width: 100% ;
    margin: 30px 0px ;
   
  }
`

export const CopyRightLinks = styled.div`
  display : flex;
  flex-direction: row ;
  gap : 10px;
  width: 100% ;
  justify-content: flex-end ;

  @media screen and (max-width: 768px){
    width: 100% ;
    justify-content: center ;
  }

`

export const CopyRightContent = styled.div`
 display: flex;
 gap:20px;
 padding: 10px 0px;
 color: ${COLORS.white};
 justify-content: space-between;
 width: ${props => props.width ? `${props.width}%` : `100%`};
 flex-direction: row;


 @media screen and (max-width: 768px){
  flex-direction: column;

  p{
    text-align : center;
  }
}
`