import React, { Component } from 'react'
import { Row } from 'Values/GlobalStyles';

import
{
  SectionContainer,
  SectionContent,
  Section,
  CopyRightLinks,
  CopyRightContent
} from './FooterStyles';


class Footer extends Component
{
  render()
  {
    return (
      <SectionContainer>
        <SectionContent>
          <Row>

            <Section>
              <h3>About Us</h3>
              <p>
                The Law Office of Ahmed B. Ismail provides the personal attention you would expect from a small law office,
                as well as the experience and resources of a large law firm. Consultations are always free. </p>
            </Section>

            <Section>
              <h3>Our Address</h3>
              <p>8 Bank Row, 3rd Flr<br />Pittsfield, MA 01201</p>
              <p>Phone: (413) 344-8398</p>
              <p>Email: aismaillaw@gmail.com</p>
              <p>Office Time: 9am-4:30pm</p>
            </Section>

            <Section>
              <h3>Usefull Links</h3>

              <p>Privacy Policy</p>
              <p>Testimonials</p>
              <p>News</p>
              <p>FAQ</p>

            </Section>

            <Section>
              <h3>Practice Area</h3>
              <p>Criminal law</p>
              <p>Civil Law</p>
              <p>Family Law</p>
            </Section>
          </Row>

          <hr />

          <CopyRightContent >
            <p style={{ width: "100%" }}>Copyright © 2022 Ahmed B. Ismail, Esq. All rights reserved.</p>

            <CopyRightLinks  >
              <p>Privacy Policy</p>
              <p>Testimonials</p>
              <p>News</p>
              <p>FAQ</p>
            </CopyRightLinks>
          </CopyRightContent>


        </SectionContent>
      </SectionContainer >
    )
  }
}

export default Footer;