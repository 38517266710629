

import emailjs from 'emailjs-com'

export const sendEmail = (e) =>
{
  return new Promise((resolve, reject) =>
  {
    emailjs.sendForm('service_7gblws4', 'template_t41fxzp', e.target, 'v5th45ga1SsclS-F_')
      .then((result) =>
      {
        console.log(result.text);
        resolve(result.text);
      }, (error) =>
      {
        this.setDialogControl(true)
        console.log(error.text);
        reject(error.text)
      });
  });
};
