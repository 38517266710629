import React, { Component } from 'react'

import
{
  SectionContainer,
  SectionContent,
  Info
} from './IntroStyles';

class Intro extends Component
{
  render()
  {
    return (
      <SectionContainer id="about">
        <SectionContent>
          <Info>
            <h2>About Ahmed B. Ismail</h2>
            <h3>A leading law firm providing professional service in the field of law!</h3>
            <p>The Law Office of Ahmed B. Ismail provides the personal attention you would expect from a small law office, as well as the experience and resources of a large law firm. Consultations are always free.</p>
          </Info>
          <Info>
            <img alt="person" src={require('Assets/Images/person.png')} />
          </Info>
          <Info>
            <p>
              We understand clients’ needs, find innovative and effective solutions,
              work hard to achieve those solutions, and do so in an efficient manner.
              We are result-oriented, cost-effective and our clients are our top priority.

            </p>
            <h2 style={{ marginBottom: "0px" }}>Ahmed B. Ismail</h2>
            <p style={{ fontSize: "medium" }}> - Owner</p>
          </Info>
        </SectionContent>
      </SectionContainer>
    )
  }
}

export default Intro;
