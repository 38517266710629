import React, { Component } from 'react'
import BarLoader from "react-spinners/BarLoader";
import MoonLoader from "react-spinners/MoonLoader"
import { COLORS } from 'Values/Theme';

class Loading extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      type: this.props.type,
    };
  }

  render()
  {
    if (this.state.type === 'MoonLoader')
    {
      return (
        <MoonLoader color={COLORS.primary} loading={true} size={50} />
      )
    }
    else
    {
      return (
        <BarLoader color={COLORS.primary} loading={true} size={150} />
      )
    }
  }
}

export default Loading

