import styled from "styled-components";
import { COLORS } from 'Values/Theme'
import Cover from 'Assets/Images/contact.jpg'

export const SectionContainer = styled.div`
display: flex;
justify-content: center;
margin-bottom: 50px ;
margin-top: 50px ;
flex-direction: row;
min-height: 800px ;
width: 100%;
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px ;
  flex-direction: row;
  justify-content: center ;
  width: 80% ;
  background-image:linear-gradient(to top,#262b3e,${COLORS.primary}),url(${Cover}); 
  background-image: linear-gradient(
          180deg,
          transparent 0%,
          #262b3e 100%
        ),
        url(${Cover}); 
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  @media screen and (max-width: 768px){
    width: 100% ;
    flex-direction: column;
    padding: 20px 0px ;

  }
`;

export const ContactMainCard = styled.div`

  display: flex ;
  flex-direction: column ;
  width: 40% ;
  padding: 30px ;
  gap : 30px;
  align-items: center ;
  justify-content: center ;
  transition: all 0.2s ease-in-out;

  img {
    width: 50px ;
  }

  h3{
    font-size: large ;
    color : white;
  }

  p{
    color: white ;
    font-size: medium ;
    border : 0px;
  }

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`

export const ContactCard = styled.div`
  display: flex ;
  flex-direction: column ;
  padding: 30px ;
  justify-content: center ;
  padding: 0px 6% ;
  width: 60% ;
  transition: all 0.2s ease-in-out;

  h1{
    color : white;
    margin-bottom: 10px ;
  }

  h4{
    color : ${COLORS.primary};
    margin-bottom: 10px ;
  }

  p{
    line-height: 1.6;
    font-size: medium ;
    margin-bottom: 20px ;
    color: white ;
  }

  form {
    display: flex ;
    gap : 20px;
    flex-direction: column ;
  }

  input {
    padding: 15px ;
    width:  100%;
    background: ${COLORS.dark_background} ;
    border: 1px solid ${COLORS.grey} ;
    font-size: large ;
    color : ${COLORS.primary};
    
    &:focus{
      border: 2px solid ${COLORS.grey} ;
      outline: none !important;
    }
  }

  textarea{
    padding: 15px ;
    width:  100%;
    background: ${COLORS.dark_background} ;
    border: 1px solid ${COLORS.grey} ;
    font-size: large ;
    color : ${COLORS.primary};

    &:focus{
      border: 2px solid ${COLORS.grey} ;
      outline: none !important;
    }
  }

  button {
    padding: 15px ;
    max-width:  200px;
    text-align: center ;
    font-size: large  ;
    cursor: pointer;
  }

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`

export const CustomRow = styled.div`
  display: flex;
  gap:50px;
  width: 50%;
  flex-direction: row;

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`

export const CustomColumn = styled.div`
 display: flex;
 flex-direction: column;
 width: 40% ;

 @media screen and (max-width: 768px){
    width: 100% ;
  }
`