import styled from "styled-components";
import { COLORS } from "Values/Theme";

export const SectionContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: row;
margin-top: -100px ;
margin-bottom: 50px ;
width: 100%;
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px ;
  justify-content: center ;
  flex-direction: row;
  width: 80% ;

  @media screen and (max-width: 768px){
    width: 90% ;
    flex-direction: column ;
  }

`;

export const ServiceCard = styled.div`
  display: flex ;
  flex-direction: row ;
  justify-content: center ;
  align-items: center ;
  gap : 20px;
  padding: 50px 30px ;
  width: 100% ;
  background: ${COLORS.secondary} ;
  z-index: 998 ;
  transition: all 0.2s ease-in-out;

  img {
    height: 50px ;
  }

  p{

  }

  h1{

  }

  &:hover{
    cursor: pointer;
    background: ${COLORS.primary} ;
    color : white;
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
    z-index: 999 ;

    img{
      filter: invert(100%);
    }

    @media screen and (max-width: 768px){
      transform: scale(1.01);
    }
  }  
`;

