import React, { Component } from 'react'
import { COLORS } from 'Values/Theme';

import
{
  Column,
  Row
} from 'Values/GlobalStyles';

import
{
  SectionContainer,
  SectionContent,
  AboutCard,
  AboutMainCard,
  CustomRow,
  CustomColumn
} from './AboutStyles';

class About extends Component
{
  render()
  {
    return (
      <SectionContainer>
        <SectionContent>

          <AboutMainCard>
            <h2>
              “Being a lawyer is not merely a vocation. It is a public trust, and each of us has an obligation to give back to our communities.”
            </h2>
            <p><b>Janet Reno</b></p>
          </AboutMainCard>
          <AboutCard>
            <h4>WE ARE EXPERT AT</h4>
            <h1>Practice Areas</h1>
            <p style={{ marginBottom: "40px" }}>
            Our law specialization list highlights some of the most popular types of law that we work on:
            </p>
            <Row >
              <CustomColumn >
                <CustomRow>
                  <img alt="skills" src={require('Assets/Images/skills.png')} />
                  <Column>
                    <h2>Criminal Law</h2>
                    <p style={{ color: COLORS.primary_tone }}>
                    A criminal case can have a devastating impact on a person’s life, keeping them from their family, 
                    their job, and possible jail time or other penalties. The Law Office of Ahmed B. Ismail can help 
                    you if you are charged with a crime by guiding you through the process and vigorously defending your rights.
                    </p>
                  </Column>
                </CustomRow>

                <CustomRow>
                  <img alt="skills" src={require('Assets/Images/skills.png')} />
                  <Column>
                    <h2>Civil Law</h2>
                    <p style={{ color: COLORS.primary_tone }}>
                    Are you having a dispute with another person or business in which compensation may be awarded to the prevailing party? 
                    Our broad range of experience in the areas of civil litigation makes us your best weapon in court.
                    </p>
                  </Column>
                </CustomRow>

                <CustomRow>
                  <img alt="skills" src={require('Assets/Images/skills.png')} />
                  <Column>
                    <h2>Family Law</h2>
                    <p style={{ color: COLORS.primary_tone }}>
                    Family law issues are highly personal and often stressful. This is a complex area of law to negotiate, 
                    and you deserve an attorney who will serve you with sensitivity to your needs and concerns.
                    </p>
                  </Column>
                </CustomRow>
              </CustomColumn>
            </Row>
          </AboutCard>
        </SectionContent>
      </SectionContainer>
    )
  }
}

export default About;
