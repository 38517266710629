import React, { Component } from 'react'

import
{
  Column,
  Row
} from 'Values/GlobalStyles';

import
{
  sendEmail
} from 'Components/Services/Email'

import
{
  SectionContainer,
  SectionContent,
  ContactCard,
  ContactMainCard,
  CustomRow,
} from './ContactStyles';
import Loading from 'Components/Utility/Loading/Loading';

class Contact extends Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      loading: false,
      showStatus: null,
      satus: 'Email sent successfully ✔️',
      statusColor: '#32a832'
    }
  }

  handleOnChange = async (e) =>
  {
    await this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = async (e) =>
  {
    e.preventDefault();
    this.setState({ loading: true })
    try
    {
      await sendEmail(e);
      this.setState({
        satus: 'Email sent successfully ✔️',
        statusColor: '#32a832',
        loading: false
      })
      await this.showSendStatus();

    }
    catch (error)
    {
      console.log(error)
      this.setState({
        satus: 'Error while sending email, try again later ❌',
        statusColor: 'red',
        loading: false
      })
      await this.showSendStatus();
    }
  }

  showSendStatus = async () =>
  {
    this.setState({
      showStatus: true
    })

    setTimeout(() =>
    {
      this.setState({
        showStatus: false,
      })
    }, 2000);
  }

  render()
  {
    return (
      <SectionContainer id="contact">
        <SectionContent>

          <ContactMainCard>
            <CustomRow style={{ alignItems: "center" }}>
              <img alt="home" src={require('Assets/Images/home.png')} />
              <Column>
                <h3>Office</h3>
                <p>8 Bank Row, 3rd Flr<br />Pittsfield, MA 01201</p>
              </Column>
            </CustomRow>

            <CustomRow style={{ alignItems: "center" }}>
              <img alt="email" src={require('Assets/Images/email.png')} />
              <Column>
                <h3>Email Address</h3>
                <p>aismaillaw@gmail.com</p>
              </Column>
            </CustomRow>

            <CustomRow style={{ alignItems: "center" }}>
              <img alt="phone" src={require('Assets/Images/phone.png')} />
              <Column>
                <h3>Telephone</h3>
                <p>(413) 344-8398</p>
              </Column>
            </CustomRow>

            <CustomRow style={{ alignItems: "center" }}>
              <img alt="alarm" src={require('Assets/Images/alarm.png')} />
              <Column>
                <h3>Office Hour</h3>
                <p>Mon-Fri: 9am – 4:30pm</p>
              </Column>
            </CustomRow>
          </ContactMainCard>
          <ContactCard>
            <h4>CONTACT FORM</h4>
            <h1>Need Consultancy,<br />Request A Free Quote</h1>
            <p style={{ marginBottom: "40px" }}>
            Contact us now to schedule your free consultation, 
            and we’ll be sure to respond within one business day. 
            We look forward to helping you get the resolution you want and deserve.
            </p>
            <form onSubmit={this.handleSubmit}>
              <Row>
                <input
                  required
                  id="name"
                  type="text"
                  onChange={this.handleOnChange}
                  placeholder='Name'
                />

                <input
                  required
                  id="email"
                  type="email"
                  name='email'
                  onChange={this.handleOnChange}
                  placeholder='Email'
                />
              </Row>

              <Row>
                <input
                  required
                  onChange={this.handleOnChange}
                  type="text"
                  id='subject'
                  name='subject'
                  placeholder="Subject" />

                <input
                  required
                  type="tel"
                  onChange={this.handleOnChange}
                  id='phone'
                  name='phone'
                  placeholder="Phone" />
              </Row>

              <textarea
                onChange={this.handleOnChange}
                required
                id='Zessage'
                name='message'
                placeholder="Text Message" />
              {this.state.loading ?
                <Loading type="MoonLoader" />
                :
                this.state.showStatus ?
                  <p style={{ color: this.state.statusColor }}><b>{this.state.satus}</b></p>
                  :
                  <button> Send Message </button>
              }
            </form>

          </ContactCard>
        </SectionContent>
      </SectionContainer>
    )
  }
}

export default Contact;
