import styled from "styled-components";
import { COLORS } from 'Values/Theme'

export const SectionContainer = styled.div`
display: flex;
justify-content: center;
margin-top: 50px ;
flex-direction: row;
width: 100%;
background: ${COLORS.darker_background}; 
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px ;
  flex-direction: row;
  justify-content: center ;
  padding: 50px ;
  width: 80% ;

  @media screen and (max-width: 768px){
    width: 90% ;
    flex-direction: column;
    align-items: center ;
  }
`;

export const Section = styled.div`
  display: flex ;
  gap : 10px;
  justify-content: center ;
  align-items: center ;
  width: 33% ;

  @media screen and (max-width: 768px){
    padding: 20px 0px ;
    width: 100% ;
  }
`

export const NewsletterContainer = styled.div`
  display: flex ;
  flex-direction: column ;
  h2{
    color : white;
    margin-bottom: 20px ;
  }

  input{
    padding : 15px;
    background: ${COLORS.dark_background} ;
    border : 0px;
    outline: 0px ;
    color : ${COLORS.white};
    font-size: large ;
  }

  @media screen and (max-width: 768px){
    width: 100% ;

    input{
      width: 100% ;
    }
    
    h2{
      text-align : center;
    }
  }

`

export const SocialMediaImage = styled.img`
  border-radius: 50% ;
  background: ${COLORS.dark_background}; 
  border: 1px solid ${COLORS.grey}; 
  width: 50px ;
  padding : 10px;
  cursor: pointer ;
  transition: all 0.2s ease-in-out;
  height: 50px ;

  &:hover{
    background: ${COLORS.primary}; 
    transition: all 0.2s ease-in-out;
  }
  
`

export const SendIconContainer = styled.div`
  display : flex;
  flex-direction: row ;
  cursor: pointer;
  justify-content: center ;
  padding : 10px;
  align-items: center ;
  background: ${COLORS.primary} ;

`

export const CustomRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`