import React from 'react'
import Cover from 'Assets/Images/cover.jpg'
import Navbar from "Components/Layout/Navbar/Navbar";

import
{
  HeroContainer,
  HeroBackground,
  ImageBackground,
  HeroContent,
  HeroH1,
  HeroP,
} from './HeroElements'
import { SecondaryContainer } from 'Values/GlobalStyles';

const Hero = () =>
{
  return (
    <>
      <HeroContainer>
        <SecondaryContainer>
          <Navbar />
          <HeroBackground>
            <ImageBackground src={Cover} />
          </HeroBackground>
          <HeroContent>
            <HeroH1>A good lawyer  <br />is one you can trust.</HeroH1>
            <HeroP>
            A lot more than a law firm.
            </HeroP>
          </HeroContent>
        </SecondaryContainer>
      </HeroContainer>

    </>
  )
}

export default Hero
