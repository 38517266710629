import styled from "styled-components";
import { COLORS } from 'Values/Theme'
import Cover from 'Assets/Images/services.png'
import { Link as LinkScroll } from 'react-scroll'

export const SectionContainer = styled.div`
display: flex;
justify-content: center;
margin-bottom: 50px ;
margin-top: 50px ;
flex-direction: row;
width: 100%;
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px ;
  gap : 20px;
  flex-direction: row;
  justify-content: center ;
  width: 80% ;

  @media screen and (max-width: 768px){
    width: 90% ;
    flex-direction: column;
  }
`;

export const ServiceMainCard = styled.div`

  /* this is needed or the background will be offset by a few pixels at the top */
  overflow: auto;
  position: relative;
  
  display: flex ;
  flex-direction: column ;
  width: 25% ;
  background-image: url(${Cover}) ;
  background-size: cover;
  background-image:linear-gradient(to bottom, rgba(245, 246, 252, 0.52), ${COLORS.primary}),url(${Cover}); 
  background-position: center; 
  background-repeat: no-repeat;
  padding: 30px ;
  align-items: center ;
  justify-content: center ;
  border: 1px solid ${COLORS.border_color} ;
  transition: all 0.2s ease-in-out;

  h2{
    margin-bottom: 20px ;
    color : white;
  }

  button{
    padding: 10px 30px ;
    font-size: medium ;
    border : 0px;
  }

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`

export const ServiceCard = styled.div`
  display: flex ;
  flex-direction: column ;
  padding: 30px ;
  width: 25% ;
  border: 1px solid ${COLORS.border_color} ;
  transition: all 0.2s ease-in-out;

  &:hover{
    cursor: pointer;
    background: ${COLORS.primary} ;
    color : white;
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    z-index: 999 ;

    img{
      filter: brightness(0) invert(1);
    }

    p{
      color : white;
    }

    @media screen and (max-width: 768px){
      transform: scale(1.01);
    }
  }  

  img{
    height: 50px ;
    width: 50px ;
    margin-bottom: 20px ;
  }

  h2{
    margin-bottom: 20px ;
  }

  h3{
    margin-bottom: 20px ;
    font-size: xx-large ;
  }

  p{
    line-height: 1.6;
    font-size: large ;
    margin-bottom: 20px ;
    color : ${COLORS.normal_font_color}
  }

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`

export const Button = styled(LinkScroll)`
  cursor : pointer;
  background: #e7e7e7 ;
  color : black;
  padding : 15px 20px;
`;