
export const COLORS = {
  background: '#ffffff',
  primary: '#C9B38C',
  secondary: '#F1F0F0',
  primary_tone : '#b9b5ad',
  black: '#000000',
  dark_background : '#262b3e',
  darker_background : '#161924',
  grey: '#999999',
  border_color : '#f3f0f0',
  lightgrey: '#f0f0f0',
  normal_font_color: '#687693',
  lightergrey: '#fcfcfc',
  white: '#ffffff',
  danger: '#DE350B',
};
