import styled from 'styled-components'

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center ;
  height: 800px;
  position: relative;
  z-index: 1;

  :before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
          180deg,
          rgba(0,0,0,0.2) 0%,
          rgba(0,0,0,0.6) 100%
        ),
        linear-gradient(180deg,rgba(0,0,0,0.2) 0% , transparent 100% );
        z-index: 2;
  }

  @media screen and (max-width: 768px){
    height: 500px;
  }
`;

export const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom:0 ;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageBackground = styled.img`
  width: 100%;
  height: 100%;
  --o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  display: flex;
  margin-top: 100px ;
  flex-direction: column;
  width: 80% ;
  @media screen and (max-width: 768px){
    width: 100% ;
  }
`;

export const HeroH1 = styled.h1`
  color: white;
  font-size: 80px;
  text-align: start ;

  @media screen and (max-width: 768px){
      font-size: 40px;
  }

  @media screen and (max-width: 480px){
      font-size: 32px;
  }

`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: white;
  font-size: 24px;
  text-align:start;
  max-width: 600px;

  @media screen and (max-width: 768px){
      font-size: 24px;
  }

  @media screen and (max-width: 480px){
      font-size: 18px;
  }

`;

