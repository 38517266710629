import React, { Component } from 'react'
import { Column } from 'Values/GlobalStyles';

import
{
  SectionContainer,
  SectionContent,
  ServiceCard
} from './CardsStyles';

class Cards extends Component
{
  render()
  {
    return (
      <SectionContainer>
        <SectionContent>
          <ServiceCard>
            <img alt="robber" src={require('Assets/Images/robber.png')} />
            <Column >
              <h3>Criminal Law</h3>
              <p >We can help you if you are charged with a crime by guiding you through the process and vigorously defending your rights.</p>
            </Column>
          </ServiceCard>
          <ServiceCard>
            <img alt="family" src={require('Assets/Images/family.png')} />
            <Column>
              <h3>Family Law</h3>
              <p >This is a complex area of law to negotiate, and you deserve an attorney who will serve you with sensitivity to your needs.</p>
            </Column>
          </ServiceCard>
          <ServiceCard>
            <img alt="stats" src={require('Assets/Images/stats.png')} />
            <Column>
              <h3>Civil Litigation</h3>
              <p >Our broad range of experience in the areas of civil litigation makes us your best weapon in court.</p>
            </Column>
          </ServiceCard>
        </SectionContent>
      </SectionContainer>
    )
  }
}

export default Cards;
