import styled from "styled-components";
import { COLORS } from 'Values/Theme'
import Cover from 'Assets/Images/lawyer.png'

export const SectionContainer = styled.div`
display: flex;
justify-content: center;
margin-bottom: 50px ;
margin-top: 50px ;
flex-direction: row;
min-height: 800px ;
width: 100%;
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px ;
  flex-direction: row;
  justify-content: center ;
  width: 80% ;

  @media screen and (max-width: 768px){
    width: 100% ;
    flex-direction: column;
  }
`;

export const AboutMainCard = styled.div`

  /* this is needed or the background will be offset by a few pixels at the top */
  overflow: auto;
  position: relative;
  
  display: flex ;
  flex-direction: column ;
  width: 40% ;
  background-size: cover;
  background-image:linear-gradient(to top,#262b3e,transparent),url(${Cover}); 
  background-position: center; 
  background-repeat: no-repeat;
  padding: 30px ;
  align-items: flex-start ;
  padding-bottom: 100px ;
  justify-content: flex-end ;
  transition: all 0.2s ease-in-out;


  h2{
    margin-bottom: 20px ;
    font-size: x-large ;
    font-weight: 400 ;
    color : white;
  }

  p{
    color: white ;
    font-size: medium ;
    border : 0px;
  }

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`

export const AboutCard = styled.div`
  display: flex ;
  flex-direction: column ;
  padding: 30px ;
  justify-content: center ;
  padding: 0px 6% ;
  width: 60% ;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(
          180deg,
          #262b3e 0%,
          #262b3e 100%
        ),
        linear-gradient(180deg,#262b3e 0% , #262b3e 100% );

  img{
    height: 50px ;
    width: 50px ;
    color: white ;
    margin-bottom: 20px ;
  }

  h1{
    color : white;
    margin-bottom: 10px ;
  }

  h2{
    margin-bottom: 10px ;
    font-size: large ;
    color: white ;
  }

  h3{
    margin-bottom: 20px ;
    font-size: xx-large ;
    color: white ;
  }

  h4{
    color : ${COLORS.primary};
    margin-bottom: 10px ;
  }

  p{
    line-height: 1.6;
    font-size: medium ;
    margin-bottom: 20px ;
    color: white ;
  }

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`

export const CustomRow = styled.div`
   display: flex;
  gap:20px;
  width: 100%;
  flex-direction: row;
`

export const CustomColumn = styled.div`
 display: flex;
 flex-direction: column;
 width: 100% ;
`