import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "./Theme";

import { AiOutlineSearch } from 'react-icons/ai'

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-height: 800px;
  gap:30px;
  width: 100%;
 `;

export const SecondaryContainer = styled.div`
  display: flex;
  margin-top: 20px ;
  flex-direction: column;
  width: 80% ;

  @media screen and (max-width: 768px){
    width: 90% ;
}
`;

export const Header = styled.div`
 display : flex;
 flex-direction: row;
 align-items: center ;
 gap:10px;
 padding:10px;
 margin-bottom: 10px;
 color : ${COLORS.primary};
 font-size: small;
`

export const SubHeader = styled.div`
 display : flex;
 flex-direction: row;
 align-items: center ;
 margin-top: 20px ;
 margin-bottom : 15px;
 gap:10px;
 margin-bottom: 10px;
 color : ${COLORS.primary};
`

export const Row = styled.div`
 display: flex;
 gap:20px;
 width: ${props => props.width ? `${props.width}%` : `100%`};
 flex-direction: row;

 @media screen and (max-width: 768px){
  flex-direction: column;
}
`

export const Column = styled.div`
 display: flex;
 flex-direction: column;
 width: ${props => props.width && `${props.width}%`};
`

export const BlockContainer = styled.div`
  width: ${props => props.width ? `${props.width}%` : `100%`};
  display: flex ;
  flex-direction: column ;
  background: ${COLORS.background};
  border-radius: 10px;
  padding : 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-left: 5px solid ${COLORS.primary};
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px){
    width : 100%;
  }
`;

export const BlockContainerLink = styled(Link)`
  width: ${props => props.width ? `${props.width}%` : `100%`};
  display: flex ;
  text-decoration: none ;
  flex-direction: column ;
  background: ${COLORS.background};
  border-radius: 10px;
  padding : 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-left: 5px solid ${COLORS.primary};
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px){
    width : 100%;
  }

   &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
    background: ${COLORS.lightgrey} ;
  }
`;



export const InfoLayout = styled.div`
  display : flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;

`

export const SelectStyle = {
  control: base => (
    {
      ...base,
      borderRadius: '5px',
      border: base.isFocused ? 0 : 0,
      boxShadow: 'none',
      zIndex: '998',
      '&:focus': {
        outlineWidth: '0px',
      },
    }),
  option: (styles, { isDisabled, isFocused }) =>
  {
    return {
      ...styles,
      backgroundColor: isFocused ? COLORS.primary : 'white',
      color: isFocused ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      zIndex: "998"

    };
  },
  menuPortal: (base) =>
  {
    return {
      ...base,
      zIndex: "998"
    }
  },
  menu: (base) =>
  {
    return {
      ...base,
      zIndex: "999"
    }
  }
};

export const SelectStyle2 = {
  control: styles => (
    {
      ...styles,
      borderRadius: '5px',
      border: '1px solid #f0f0f0',
      marginBottom: "20px",
      padding: "5px",
      minWidth: "200px",
      width: "100%",
      '&:hover': {
        borderColor: COLORS.primary,
        border: '2px solid #3a8d9d',
      },
    }),
  option: (styles, { isDisabled, isFocused }) =>
  {
    return {
      ...styles,
      backgroundColor: isFocused ? COLORS.primary : 'white',
      color: isFocused ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

    };
  },
};

export const Button = styled.button`

  background: ${props => props.background ? props.background : `${COLORS.primary}`};
  padding: 10px 40px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  font-size: large;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
  }
`;

export const LinkButton = styled(Link)`
  background: ${COLORS.white};
  padding: 10px 40px;
  color: black;
  border : 1px solid ${COLORS.lightgrey};
  cursor: pointer;
  text-align: center ;
  border-radius: 5px;
  max-width: 250px ;
  margin-bottom: 20px ;
  text-decoration: none;
  font-size: large;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
    background: ${COLORS.primary} ;
    color : white;
    border : none;
  }

`;

export const Section = styled.div`
 width: 70%;
`

export const SearchContainer = styled.div`
display : flex;
align-items: center;
flex-direction: row;
border: 1px solid ${COLORS.lightgrey};
border-radius: 5px; 
margin-bottom: 20px;

input {
  width: 100%;
  border : 0px;
  padding : 10px 20px;
  font-size: medium;

  &:focus {
  border : 0px solid ${COLORS.primary};
  outline-width: 0;
}
}
`

export const AiOutlineSearchStyled = styled(AiOutlineSearch)`
cursor: pointer;
padding : 10px 10px;
border-left: 1px solid ${COLORS.lightgrey} ;
width: 45px;
height:45px;
`

export const RowProperty = styled.div`
  display : flex;
  flex-direction: row;
  align-items: center;
  gap : 10px;
  padding : 5px;

  h3{
    font-size: 15px ;
  }

  input{
    font-size: 14px ;
    padding: 10px 5px;
    border: 0px;
    border-radius: 5px;

    &:focus{
      outline-width: 0 ;
      background : ${COLORS.lightgrey};
      border-radius: 5px;
      border: 0 ;
    }
  }

`

export const HeaderProperty = styled.div`
  display : flex;
  flex-direction: row;
  align-items: center;
  gap : 10px;
  background: ${COLORS.background};
  border-radius: 10px;
  padding : 10px 20px 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  h3{
    font-size: 20px ;
  }

  p {
    color : ${COLORS.primary}
    font-size: 15px ;
  }

`

export const Property = styled.div`
  display: flex ;
  flex-direction: column ;
  gap:5px;
  padding : 10px;
  border-radius: 5px ;
  border-bottom: ${props => props.last ? `0px` : `1px solid ${COLORS.lightgrey}`};
  margin-bottom: ${props => props.last ? `0px` : '10px'};

  h3{
    font-size: 15px ;
  }

  p{
    font-size: 14px ;
  }

  input{
    font-size: 14px ;
    padding: 10px 5px;
    border: 0px;
    border-radius: 5px;

    &:focus{
      outline-width: 0 ;
      background : ${COLORS.lightgrey};
      border-radius: 5px;
      border: 0 ;
    }
  }

  textarea{
    text-decoration: none;
    overflow: hidden; 
    font-size: medium;
    padding : 10px;
    border-radius: 5px;
    border: 0px solid;

    &:focus{
      outline-width: 0 ;
      background : ${COLORS.lightgrey};
      border-radius: 5px;
      border: 0 ;
    }
  }
`

export const LinkProperty = styled(Link)`
  display: flex ;
  flex-direction: column ;
  gap:5px;
  padding : 10px;
  text-decoration: none ;
  color : black;
  border-radius: 5px ;
  border : 1px solid ${COLORS.lightgrey};
  margin-bottom: ${props => props.last ? `0px` : '10px'};
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
    background: ${COLORS.primary} ;
    color : white;
  }

  p{
    font-size: 14px ;
  }
`


export const ActionProperty = styled.div`
  display: flex ;
  flex-direction: column ;
  gap:5px;
  padding : 10px;
  cursor: pointer;
  border-radius: 5px ;
  border : 1px solid ${COLORS.lightgrey};
  margin-bottom: ${props => props.last ? `0px` : '10px'};
  transition: all 0.2s ease-in-out;

  p{
    font-size: 14px ;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
    background: ${COLORS.primary} ;
    color : white;
  }
`

export const PhotoContainer = styled.div`
  display: flex;
  border-bottom: 1px solid lightgrey;
  flex-direction: row;
  padding: 20px ;
  justify-content: space-between;

  @media screen and (max-width: 768px){
    flex-direction: column ;
    gap :  5px;
  }
`

export const UploadLabel = styled.label`
  background: ${COLORS.primary};
  padding: 5px 10px;
  color: white;
  min-width: 200px ;
  text-align: center ;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  font-size: large;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
  }
`

export const Image = styled.img`
  cursor: pointer;
  height: 100px;
  border-radius: 10px;
  transition: 0.3s ease-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.5);
  }

`
