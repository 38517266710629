import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: require('Assets/Images/Picture1.jpg'),
    thumbnail: require('Assets/Images/Picture1.jpg'),
  },
  {
    original: require('Assets/Images/Picture2.jpg'),
    thumbnail: require('Assets/Images/Picture2.jpg'),
  },
  {
    original: require('Assets/Images/Picture3.jpg'),
    thumbnail: require('Assets/Images/Picture3.jpg'),
  },
  {
    original: require('Assets/Images/Picture4.jpg'),
    thumbnail: require('Assets/Images/Picture4.jpg'),
  },
  {
    original: require('Assets/Images/Picture5.jpg'),
    thumbnail: require('Assets/Images/Picture5.jpg'),
  }
];

class Gallery extends Component
{
  render()
  {
    return (
      <ImageGallery
       items={images}
       showFullscreenButton={false}
       showNav={false}
       lazyLoad={true}
       autoPlay={true}
       slideDuration={400}
       showPlayButton={false}
        />
    )
  }
}
export default Gallery
