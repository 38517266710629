import React, { Component } from 'react'
import Hero from 'Components/Layout/Hero/Hero';
import Cards from 'Components/Layout/Cards/Cards';
import Intro from 'Components/Layout/Intro/Intro';
import Services from 'Components/Layout/Services/Services';
import About from 'Components/Layout/About/About';
import Contact from 'Components/Layout/Contact/Contact';
import SocialMedia from 'Components/Layout/SocialMedia/SocialMedia';
import Footer from 'Components/Layout/Footer/Footer';
import Gallery from 'Components/Layout/Gallery/Gallery';


class Home extends Component
{
  render()
  {
    return (
      <>
        <Hero />
        <Cards />
        <Intro />
        <Services />
        <About />
        <Gallery />
        <Contact />
        <SocialMedia />
        <Footer />
      </>
    );
  }
}

export default Home
