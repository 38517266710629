import styled from 'styled-components'
import { Link as LinkRouter } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import { COLORS } from 'Values/Theme';

export const Nav = styled.nav`
  background: transparent;
  height : 80px;
  display : flex;
  border-bottom: 1px solid #636363 ;
  flex-direction: row ;
  justify-content: center ;
  padding: 10px ;
  font-size :1rem;
  top : 0;
  z-index : 10;
  transition: 0.6s ease-in-out;
 
  @media screen and (max-width: 960px) {
      transition: 0.8s all escape;
  }

`;

export const NavbarContainer = styled.div`
  display: flex;
  left:0;
  right:0;
  width: 50% ;
  height: 80px;
  z-index: 1;
  width: 80%;
`;

export const NavLogo = styled(LinkRouter)`
  color : white;
  font-size: x-large ;
  width: 30% ;
  gap : 10px;
  display: flex;
  align-items:center;
  text-decoration:none;

  @media screen and (max-width : 768px){
  display: flex;
  align-self: center;
  width: auto ;
  color: white;
  }

  img {
    cursor: pointer;
    height: 80px ;
  }
`;

export const MobileIcon = styled.div`
  display: flex;
  visibility: hidden;
  position: absolute;
  right:20px;
  @media screen and (max-width: 768px){
  align-self: center;
  font-size: 1.8rem;
  cursor:pointer;
  color: white;
  visibility: hidden;
}
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  width: 70% ;
  list-style: none;
  justify-self: center ;
  text-align: center;
  
  @media screen and (max-width : 768px){
      display: none;
      width: auto ;
  }

`;
export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkScroll)`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: x-large ;
  text-decoration: none;
  padding: 0 1rem;
  height :100%;
  cursor: pointer;

  &:active{
      border-bottom: 3px solid ${COLORS.background};
  }

  &:hover{
    color : ${COLORS.primary};
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  @media screen and (max-width: 768px){
      display: none;
  }
`;

export const NavBtnLink = styled(LinkRouter)`
border-radius: 50px;
background: #01bf71;
white-space: nowrap;
padding: 10px 20px; 
color: #010606;
align-self: center;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;

&:hover {
    transition: all 0.2s ease-in-out;
    background: white;
    color: #010606;
}
`;
