import styled from "styled-components";
import { COLORS } from 'Values/Theme'

export const SectionContainer = styled.div`
display: flex;
justify-content: center;
margin-bottom: 50px ;
margin-top: 50px ;
flex-direction: row;
min-height: 500px;
width: 100%;
`;

export const SectionContent = styled.div`
  display: flex;
  margin-top: 20px ;
  gap : 20px;
  flex-direction: row;
  justify-content: center ;
  width: 80% ;

  @media screen and (max-width: 768px){
    width: 90% ;
    flex-direction: column;
  }
`;

export const Info = styled.div`
  display: flex ;
  flex-direction: column ;
  width: 33% ;

  img{
    object-fit: cover ;
  }

  h2{
    margin-bottom: 20px ;
  }

  h3{
    margin-bottom: 20px ;
    font-size: xx-large ;
  }

  p{
    line-height: 1.6;
    font-size: large ;
    margin-bottom: 20px ;
    color : ${COLORS.normal_font_color}
  }

  @media screen and (max-width: 768px){
    width: 100% ;
  }
`