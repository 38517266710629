import React, { Component } from 'react'

import
{
  FiSend
} from 'react-icons/fi'

import
{
  SectionContainer,
  SectionContent,
  Section,
  SocialMediaImage,
  NewsletterContainer,
  SendIconContainer,
  CustomRow
} from './SocialMediaStyles';
import { COLORS } from 'Values/Theme';

class SocialMedia extends Component
{
  render()
  {
    return (
      <SectionContainer>
        <SectionContent>
          <Section>
            <a href="https://www.facebook.com/Attorney-Ismail-109539755118193/"
              target="_blank"
              rel="noopener noreferrer">
              <SocialMediaImage alt="logo" src={require('Assets/Images/facebook.png')} />
            </a>
            <a href="https://attorneyismail.com/"
              target="_blank"
              rel="noopener noreferrer">
              <SocialMediaImage alt="logo" src={require('Assets/Images/twitter.png')} />
            </a>
            <a href="https://www.linkedin.com/in/ahmed-ismail-58299166/" target="_blank"
              rel="noopener noreferrer">
              <SocialMediaImage alt="logo" src={require('Assets/Images/linkedin.png')} />
            </a>
          </Section>
          <Section>
            <img style={{ width: "100px" }} alt="logo" src={require('Assets/Images/logo.png')} />
          </Section>
          <Section>
            <NewsletterContainer>
              <h2>Newsletter</h2>
              <CustomRow >
                <input
                  id="newsLetter"
                  type="text"
                  placeholder='Email Address'
                />
                <SendIconContainer>
                  <FiSend color={COLORS.white} size={30} />
                </SendIconContainer>
              </CustomRow>
            </NewsletterContainer>

          </Section>
        </SectionContent>
      </SectionContainer>
    )
  }
}

export default SocialMedia;