import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from "Pages/Home/Home";

class App extends Component
{

  renderApp()
  {
    return (

      <BrowserRouter >
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
        </Routes>
      </BrowserRouter>
    )
  }

  render()
  {
    return (
      <>
        {this.renderApp()}
      </>
    )
  }
}

export default App