import React, { useState, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'

import { animateScroll as scroll } from 'react-scroll'
import
{
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from './NavbarStyles'

function Navbar({ toggle })
{
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () =>
  {
    if (window.scrollY >= 80)
    {
      setScrollNav(true)
    } else
    {
      setScrollNav(false)
    }
  }

  useEffect(() =>
  {
    window.addEventListener('scroll', changeNav);

    return () => window.removeEventListener("scroll", changeNav);
  }, [])

  const toggleHome = () =>
  {
    scroll.scrollToTop();
  }

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo
            to='/'
            onClick={toggleHome}>
            <img alt="logo" src={require('Assets/Images/logo.png')} />
            <p>Ahmed B. Ismail, Esq.</p>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars>
            </FaBars>
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                exact='true'
                offset={-80}>
                Why Us
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="services"
                smooth="true"
                duration={500}
                exact='true'
                offset={-80}>
                Services</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="about"
                smooth="true"
                duration={500}
                exact='true'
                offset={-80}>
                About</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="contact"
                smooth="true"
                duration={500}
                exact='true'
                offset={-80}>
                Contact</NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  )
}

export default Navbar
