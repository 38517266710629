import React, { Component } from 'react'

import
{
  SectionContainer,
  SectionContent,
  ServiceCard,
  ServiceMainCard,
  Button
} from './ServicesStyles';

class Services extends Component
{
  render()
  {
    return (
      <SectionContainer id="services">
        <SectionContent>

          <ServiceMainCard>
            <h2>Free<br/>Confidential Counseling.<br/>Get Questions Answered!</h2>
            <Button
              to="contact"
              smooth={true}
              duration={500}
              exact='true'>
              Contact us
            </Button>
          </ServiceMainCard>
          <ServiceCard>
            <img alt="skills" src={require('Assets/Images/skills.png')} />
            <h2>Skilled Attorneys</h2>
            <p>The Law Office of Ahmed B. Ismail provides the personal attention you would expect from a small law office, as well as the experience and resources of a large law firm.</p>
          </ServiceCard>
          <ServiceCard>
            <img alt="balance" src={require('Assets/Images/balance.png')} />
            <h2>Free Consultations</h2>
            <p>We give you the chance to determine if we are the best for you , and decide if we are a good fit to help you.</p>
          </ServiceCard>
          <ServiceCard>
            <img alt="care" src={require('Assets/Images/care.png')} />
            <h2>Personal Attention</h2>
            <p>Our firm provides a high level of dedication to our clients so that we can utilize the most advantageous strategy to obtain the benefits they need.</p>
          </ServiceCard>
        </SectionContent>
      </SectionContainer>
    )
  }
}

export default Services;
